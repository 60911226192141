<template>
  <div class="special-ico">
    <Header titleurl="special" />
    <div class="content">
      <div class="selector">
        <div
          class="J_selectorLine s-category"
          v-if="cate1 !== undefined && cate1.length > 0"
        >
          <div class="sl-wrap">
            <div class="sl-key">
              <strong>评估:</strong>
            </div>
            <div class="sl-value">
              <div class="sl-v-list">
                <ul class="J_valueList">
                  <li v-for="(item, n) in cate1" :key="n">
                    <a
                      :title="item.name"
                      class="cate"
                      :class="cate1Id == item.id ? 'selected' : ''"
                      href="javascript:;"
                      @click="ongetsubcatelist(item.id, 1)"
                      ><i></i>{{ item.name }}</a
                    >
                  </li>
                </ul>
              </div>
              <div class="sl-btns">
                <a
                  class="btn btn-primary J_btnsConfirm disabled"
                  href="javascript:;"
                  >确定</a
                >
                <a class="btn btn-default J_btnsCancel" href="javascript:;"
                  >取消</a
                >
              </div>
            </div>
            <div class="sl-ext">
              <a
                class="sl-e-more J_extMore"
                href="javascript:;"
                style="visibility: hidden;"
                >更多<i></i
              ></a>
              <a
                class="sl-e-multiple J_extMultiple"
                href="javascript:;"
                style="visibility: hidden;"
                >多选<i></i
              ></a>
            </div>
          </div>
        </div>
        <div
          class="J_selectorLine s-category"
          v-if="cate2 !== undefined && cate2.length > 0"
        >
          <div class="sl-wrap">
            <div class="sl-key">
              <strong>酒类/茶叶:</strong>
            </div>
            <div class="sl-value">
              <div class="sl-v-list">
                <ul class="J_valueList">
                  <li v-for="(item, n) in cate2" :key="n">
                    <a
                      :title="item.name"
                      class="cate"
                      :class="cate2Id == item.id ? 'selected' : ''"
                      href="javascript:;"
                      @click="ongetsubcatelist(item.id, 2)"
                      ><i></i>{{ item.name }}</a
                    >
                  </li>
                </ul>
              </div>
              <div class="sl-btns">
                <a
                  class="btn btn-primary J_btnsConfirm disabled"
                  href="javascript:;"
                  >确定</a
                >
                <a class="btn btn-default J_btnsCancel" href="javascript:;"
                  >取消</a
                >
              </div>
            </div>
            <div class="sl-ext">
              <a
                class="sl-e-more J_extMore"
                href="javascript:;"
                style="visibility: hidden;"
                >更多<i></i
              ></a>
              <a
                class="sl-e-multiple J_extMultiple"
                href="javascript:;"
                style="visibility: hidden;"
                >多选<i></i
              ></a>
            </div>
          </div>
        </div>
        <div
          class="J_selectorLine s-category"
          v-if="cate3 !== undefined && cate3.length > 0"
        >
          <div class="sl-wrap">
            <div class="sl-key">
              <strong>定制加工:</strong>
            </div>
            <div class="sl-value">
              <div class="sl-v-list">
                <ul class="J_valueList">
                  <li v-for="(item, n) in cate3" :key="n">
                    <a
                      :title="item.name"
                      class="cate"
                      :class="cate3Id == item.id ? 'selected' : ''"
                      href="javascript:;"
                      @click="ongetsubcatelist(item.id, 3)"
                      ><i></i>{{ item.name }}</a
                    >
                  </li>
                </ul>
              </div>
              <div class="sl-btns">
                <a
                  class="btn btn-primary J_btnsConfirm disabled"
                  href="javascript:;"
                  >确定</a
                >
                <a class="btn btn-default J_btnsCancel" href="javascript:;"
                  >取消</a
                >
              </div>
            </div>
            <div class="sl-ext">
              <a
                class="sl-e-more J_extMore"
                href="javascript:;"
                style="visibility: hidden;"
                >更多<i></i
              ></a>
              <a
                class="sl-e-multiple J_extMultiple"
                href="javascript:;"
                style="visibility: hidden;"
                >多选<i></i
              ></a>
            </div>
          </div>
        </div>
        <div
          class="J_selectorLine s-category"
          v-if="cate4 !== undefined && cate4.length > 0"
        >
          <div class="sl-wrap">
            <div class="sl-key">
              <strong>测绘:</strong>
            </div>
            <div class="sl-value">
              <div class="sl-v-list">
                <ul class="J_valueList">
                  <li v-for="(item, n) in cate4" :key="n">
                    <a
                      :title="item.name"
                      class="cate"
                      :class="cate4Id == item.id ? 'selected' : ''"
                      href="javascript:;"
                      @click="ongetsubcatelist(item.id, 4)"
                      ><i></i>{{ item.name }}</a
                    >
                  </li>
                </ul>
              </div>
              <div class="sl-btns">
                <a
                  class="btn btn-primary J_btnsConfirm disabled"
                  href="javascript:;"
                  >确定</a
                >
                <a class="btn btn-default J_btnsCancel" href="javascript:;"
                  >取消</a
                >
              </div>
            </div>
            <div class="sl-ext">
              <a
                class="sl-e-more J_extMore"
                href="javascript:;"
                style="visibility: hidden;"
                >更多<i></i
              ></a>
              <a
                class="sl-e-multiple J_extMultiple"
                href="javascript:;"
                style="visibility: hidden;"
                >多选<i></i
              ></a>
            </div>
          </div>
        </div>
      </div>
      <div class="contents">
        <div class="contentsRight">
          <div class="FilterList">
            <div class="FilterListLeft">
              <div
                class="filterBox1"
                v-for="(item, index) in nameList"
                :key="index"
                @click="getIndexs(index)"
                :class="nameIndex == index ? 'active' : ''"
              >
                <span>{{ item.name }}</span>
                <span class="filterBoxIcon"
                  ><i class="el-icon-caret-bottom"></i
                ></span>
              </div>
              <div
                class="filterBox5"
                @click="getfilterBox5Click"
                :class="filterBox5 ? 'active' : ''"
              >
                <div class="filterBox5Title">价格</div>
                <div class="filterBox5Div1">
                  <div
                    class="filterBox5Div2"
                    @click="filterBox5Div2"
                    :class="filterBox5Div1 ? '' : 'active2'"
                  >
                    <i class="el-icon-caret-top"></i>
                  </div>
                  <div
                    class="filterBox5Div3"
                    @click="filterBox5Div3"
                    :class="filterBox5Div1 ? 'active2' : ''"
                  >
                    <i class="el-icon-caret-bottom"></i>
                  </div>
                </div>
              </div>
              <!-- 输入框筛选 -->
              <div
                class="filterForm"
                @mouseover="formMouseover"
                @mouseout="formMouseout"
              >
                <el-form>
                  <div class="FilterListInputBox">
                    <el-form-item>
                      <el-input
                        class="input1"
                        v-model="minPrice"
                        placeholder="￥"
                      ></el-input>
                    </el-form-item>
                    <span class="lineIcon">-</span>
                    <el-form-item>
                      <el-input
                        class="input1"
                        v-model="maxPrice"
                        placeholder="￥"
                      ></el-input>
                    </el-form-item>
                  </div>
                  <div class="FilterListInputBtn" v-show="filterForm">
                    <el-button size="mini" @click="empty">清空</el-button>
                    <el-button size="mini" @click="submit">确认</el-button>
                  </div>
                </el-form>
              </div>
            </div>
            <div class="FilterListRight">
              <div class="filterRDescribe">
                <span>共</span>
                <span class="filtercolor">{{ totalCount }}</span>
                <span>件商品</span>
              </div>
              <div class="filterRPrice">
                <span class="filtercolor1">{{ currPageIndex }}</span>
                <span>/{{ totalPage }}</span>
              </div>
              <div class="filterRBtn">
                <div class="filterBox6">
                  <span class="filterBoxIcon"
                    ><i class="el-icon-arrow-left"></i
                  ></span>
                </div>
                <div class="filterBox7">
                  <span class="filterBoxIcon"
                    ><i class="el-icon-arrow-right"></i
                  ></span>
                </div>
              </div>
            </div>
          </div>
          <div class="commodity">
            <div
              class="container_row_spec custom"
              v-for="(item, i) in productlist"
              :key="i"
            >
              <router-link
                :to="'/product/detail?uniqueNo=' + item.uniqueNo"
                target="_blank"
              >
                <!-- <el-image :src="item.headImg" fit="cover">
                                  <div slot="error" class="image-slot">
                                    <i class="el-icon-picture-outline"></i>
                                  </div>
                                </el-image>
								<div class="container_row_map">{{ item.title }}</div>
								<div class="container_row_place pricebox">
									<b class="price"><span class="rmb">¥</span>{{ item.price }}</b>
								</div> -->
                <div class="item-img">
                  <el-image :src="item.headImg" fit="cover">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                  <div class="product-title">
                    {{ item.title }}
                  </div>
                  <div class="product-price">
                    <template v-if="item.billingMode != ''">
                      <!-- <span class="rmb">
                                      {{ item.billingMode }}
                       </span> -->
                      <span
                        class="rmb"
                        v-if="item.billingVal.indexOf('%') == -1"
                      >
                        ¥
                      </span>
                      {{ item.billingVal }}
                    </template>
                    <template v-else>
                      <span class="rmb">¥</span>{{ item.price }}
                    </template>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
          <div class="page">
            <el-pagination
              @size-change="handleSizeChange"
              background
              @current-change="handleCurrentChange"
              :current-page="pageIndex"
              :page-sizes="[12, 24, 36]"
              :page-size="pageSize"
              layout="total, prev, pager, next"
              :total="totalCount"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/header.vue';
import Footer from '@/components/footer.vue';
import { productgetproductlist, productgetproductcate } from '@/api/product';
export default {
  name: 'productdisplay',
  components: {
    Header,
    Footer
  },
  data() {
    return {
      defaults: 'this.src="' + require('@/assets/img/house_money.png') + '"',
      // 筛选标题数据
      nameList: [
        {
          name: '综合'
        },
        {
          name: '销量'
        },
        {
          name: '评论数'
        },
        {
          name: '新品'
        }
        // {
        //     name: '价格',
        // }
      ],
      // 数字类型的变量
      nameIndex: 0,
      filterBox5: false,
      filterBox5Div1: false,
      // 经过与离开筛选框时，判断显示与隐藏
      filterForm: false,
      pageIndex: 1,
      pageSize: 20,
      // companyId: 0,
      // isRecommend: 0,
      cate1Id: null,
      cate2Id: null,
      cate3Id: null,
      cate4Id: null,
      minPrice: null,
      maxPrice: null,
      sortDirection: 0,
      keyword: '',
      totalCount: 1,
      currPageIndex: 1,
      totalPage: 1,
      productlist: [],
      cate1: [],
      cate2: [],
      cate3: [],
      cate4: []
    };
  },
  mounted() {
    this.cate2Id = this.$route.query.cateId;
    this.onproductgetcustomlist();
    this.onproductgetcustomscreen();
  },
  methods: {
    ongetsubcatelist(item, i) {
      this.pageIndex = 1;
      if (i === 1) {
        this.cate1Id = item;
      } else if (i === 2) {
        this.cate2Id = item;
      } else if (i === 3) {
        this.cate3Id = item;
      } else if (i === 4) {
        this.cate4Id = item;
      }
      this.onproductgetcustomlist();
    },
    onproductgetcustomscreen() {
      var that = this;
      productgetproductcate({}).then(res => {
        if (res.data.code == 1) {
          // that.cate1 = res.data.result.cate1;
          // that.cate2 = res.data.result.cate2;
          // that.cate3 = res.data.result.cate3;
          // that.cate4 = res.data.result.cate4;
        }
      });
    },
    onproductgetcustomlist() {
      var that = this;
      productgetproductlist({
        // companyId: this.companyId,
        // isRecommend: this.isRecommend,
        // cate1Id: this.cate1Id,
        cate2Id: this.cate2Id,
        // cate3Id: this.cate3Id,
        // cate4Id: this.cate4Id,
        minPrice: this.minPrice,
        maxPrice: this.maxPrice,
        sortRule: this.nameIndex,
        sortDirection: this.sortDirection,
        keyword: this.keyword,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      }).then(res => {
        if (res.data.code == 1) {
          that.productlist = res.data.result.list;
          that.totalCount = res.data.result.totalCount;
          that.currPageIndex = res.data.result.currPageIndex;
          that.totalPage = res.data.result.totalPage;
        }
      });
    },
    handleCurrentChange(item) {
      console.log(item);
      this.pageIndex = item;
      this.onproductgetcustomlist();
    },
    handleSizeChange(item) {
      this.pageSize = item;
      this.onproductgetcustomlist();
    },
    getIndexs(index) {
      this.nameIndex = index;
      this.onproductgetcustomlist();
      this.filterBox5 = false;
    },
    empty() {
      this.minPrice = null;
      this.maxPrice = null;
      this.pageIndex = 1;
      this.onproductgetcustomlist();
    },
    submit() {
      this.pageIndex = 1;
      this.onproductgetcustomlist();
    },
    getfilterBox5Click() {
      this.filterBox5 = true;
      this.nameIndex = 4;
      this.filterBox5Div1 = true;
      this.onproductgetcustomlist();
    },
    filterBox5Div2(e) {
      e.stopPropagation();
      this.filterBox5Div1 = true;
      this.sortDirection = 0;
      this.onproductgetcustomlist();
    },
    filterBox5Div3(e) {
      this.filterBox5Div1 = false;
      e.stopPropagation();
      this.sortDirection = 1;
      this.onproductgetcustomlist();
    },
    formMouseover() {
      this.filterForm = true;
    },
    formMouseout() {
      this.filterForm = false;
    }
  }
};
</script>

<style scoped>
a {
  color: #606266;
}

body {
  background-color: #ffffff;
  /* height: 100%; */
}

.wrap {
  width: 1190px;
  margin-left: auto;
  margin-right: auto;
}

.special-ico {
  min-height: 100%;
  height: auto !important;
  height: 100%;
  position: relative;
}

.content {
  width: 100%;
  margin-top: 20px;
  width: 1200px;
  margin: 0 auto;
  margin-top: 20px;
  min-height: 700px;
}

.contents {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.special-ico .clearfix {
  clear: both;
}

.contentsRight {
  width: 1190px;
}

.FilterList {
  display: flex;
  justify-content: space-between;
  width: 1190px;
  height: 40px;
  border-top: 1px solid #e7e3e7;
  border-bottom: 1px solid #e7e3e7;
  background-color: #ffffff;
}

.FilterListLeft {
  display: flex;
  flex-direction: row;
  margin-left: 10px;
}

.filterBox1 {
  cursor: pointer;
  width: 60px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  margin-top: 7px;
  color: #999;
  border-top: 1px solid #999;
  border-left: 1px solid #999;
  border-bottom: 1px solid #999;
}
.filterBox1:nth-child(5) {
  border-right: 1px solid #999;
}
.filterBox5 {
  width: 60px;
  height: 25px;
  margin-top: 7px;
  color: #999;
  cursor: pointer;
}
.filterBox5 {
  display: flex;
  flex-direction: row;
  border: 1px solid #999;
  margin-right: 5px;
}

.filterBoxIcon {
  margin-left: 3px;
}

.input1 {
  width: 62px;
  height: 28px;
  margin-top: 7px;
  margin-left: 5px;
  margin-right: 5px;
}

.input1 >>> .el-input__inner {
  /* border-radius: 4px; */
  border: 1px solid #999;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  /* height: 40px; */
  /* line-height: 40px; */
  height: 26px;
  line-height: 26px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}

.lineIcon {
  display: block;
  margin-top: 20px;
}

.FilterListRight {
  display: flex;
  flex-direction: row;
  margin-right: 10px;
}

.filterRDescribe {
  height: 25px;
  text-align: center;
  line-height: 25px;
  margin-top: 7px;
  color: #999;
  margin-right: 15px;
}

.filtercolor {
  color: #666;
}

.filterRPrice {
  height: 25px;
  text-align: center;
  line-height: 25px;
  margin-top: 7px;
  color: #999;
  margin-right: 15px;
}

.filtercolor1 {
  color: #e4393c;
}

.filterRBtn {
  display: flex;
  flex-direction: row;
}

.filterBox6,
.filterBox7 {
  width: 50px;
  height: 26px;
  text-align: center;
  line-height: 26px;
  margin-top: 7px;
  color: #cccccc;
}

.filterBox6 {
  border-top: 1px solid #cccccc;
  border-left: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
}

.filterBox7 {
  border: 1px solid #cccccc;
  margin-right: 10px;
}

.commodity {
  display: flex;
  flex-wrap: wrap;
}

.container_row_spec {
  width: 220px;
  height: 303px;
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
  transition: all 0.2s linear;
  margin-top: 20px;
  margin-left: 10px;
}

.container_row_spec:hover {
  transform: translate3d(0, -2px, 0);
}

.container_row_img {
  cursor: pointer;
}

.container_row_spec:nth-child(5),
.container_row_spec:nth-child(10) {
  margin-right: 0px !important;
}

.container_row_name {
  color: #666;
  font-size: 14px;
  background-color: #fff;
  word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  padding: 0 10px;
  margin-top: 10px;
}

.container_row_name img {
  position: relative;
  top: 5px;
  margin-left: 9px;
}

.container_row_name1 {
  color: #999;
  font-size: 14px;
  /* width: 100%; */
  background-color: #fff;
  word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  padding: 0 10px;
  margin-top: 10px;
}

.container_row_name1 img {
  position: relative;
  top: 5px;
  margin-left: 9px;
}

.container_row_price {
  background-color: #fff;
  font-size: 20px;
  padding-left: 10px;
  color: #e4393c;
  margin-top: 5px;
}

.container_row_price .rmb {
  font-size: 20px;
  color: #e4393c;
}

.container_row_spec {
  height: auto;
}

.container_row_price .mode {
  font-size: 12px;
  color: #666;
}

.active {
  background-color: #e4393c;
  color: #fff;
  border-top: 1px solid #e4393c;
  border-left: 1px solid #e4393c;
  border-bottom: 1px solid #e4393c;
}

.filterBox5Div1 {
  display: flex;
  flex-direction: column;
}

.filterBox5Title {
  margin-left: 10px;
  margin-top: 3px;
  margin-right: 2px;
}

.filterBox5Div2 {
  margin-top: 1px;
}

.filterBox5Div3 {
  margin-top: -6px;
}

.active2 {
  color: #999;
}

.FilterListInputBox {
  display: flex;
  flex-direction: row;
  margin-top: -6px;
}

.filterForm {
  display: flex;
  flex-direction: column;
  height: 80px;
  z-index: 99;
}

.filterForm:hover {
  border: 1px solid #999;
  box-shadow: 1px 0px 0px #d0d0d0;
  background: #fff;
}

.FilterListInputBtn {
  padding: 5px;
  display: flex;
  justify-content: space-between;
  margin-top: -20px;
}

.container_row_spec {
  width: 220px;
  height: 263px;
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
  transition: all 0.2s linear;
}

.container_row_spec img.container_row_img {
  width: 100%;
  height: 220px;
}

.container_row_spec:hover {
  transform: translate3d(0, -2px, 0);
}

.container_row_img {
  cursor: pointer;
}

/* .container_row_spec:nth-child(5),
.container_row_spec:nth-child(10) {
  margin-right: 0px !important;
} */
.container_row_name {
  color: #666;
  height: 35px;
  font-size: 12px;
  width: 100%;
  /* margin-top: -4px; */
  background-color: #fff;
  word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  line-height: 40px;
}

.container_row_name img {
  position: relative;
  top: 5px;
  margin-left: 9px;
}

.container_row_price {
  background-color: #fff;
  font-size: 20px;
  padding: 0 10px;
  color: #b31e22;
}

.container_row_price .rmb {
  font-size: 12px;
  color: #666;
}

.container_row_spec.custom {
  height: 180px;
  width: 180px;
  position: relative;
  margin-bottom: 20px;
}
.container_row_spec.custom img {
  border-radius: 10%;
  border: 1px solid #ededed;
}

.container_row_price .mode {
  font-size: 12px;
  color: #666;
}

.container_row_map {
  width: 100%;
  margin-top: 0;
  height: 28px;
  opacity: 0.76;
  bottom: 0px;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 28px;
  padding-left: 9px;
  box-sizing: border-box;
  color: #666;
  text-align: center;
}
.container_row_place.pricebox {
  text-align: center;
}
.pricebox .price {
  color: #ff0000cc;
  font-size: 12px;
  font-weight: bold;
}

.pricebox .price .rmb {
  font-size: 12px;
}

.pricebox .delprice {
  font-size: 12px;
  color: #999;
  text-decoration: line-through;
  margin-left: 16px;
  font-weight: normal;
}

.clearfix {
  clear: both;
}

.page {
  margin-top: 30px;
  text-align: center;
}

.selector {
  border-top: 1px solid #ddd;
  background: #fff;
  margin-bottom: 10px;
}

.selector .sl-wrap {
  /* position: relative; */
  line-height: 34px;
  border-bottom: 1px solid #ddd;
  background: #f3f3f3;
}

.selector .sl-key {
  float: left;
  width: 100px;
  padding-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  line-height: 40px;
}

.selector .sl-value {
  margin-left: 110px;
  padding-right: 130px;
  padding-left: 10px;
  overflow: hidden;
  zoom: 1;
  background: #fff;
}

.selector .sl-v-list {
  overflow: hidden;
  zoom: 1;
  padding-top: 5px;
  padding-bottom: 5px;
}

.selector .sl-v-list ul {
  float: left;
  overflow: hidden;
  zoom: 1;
  position: relative;
  /* height: 30px; */
}

.selector .sl-v-list li {
  float: left;
  _display: inline;
  margin-right: 50px;
  height: 30px;
  line-height: 30px;
}

.selector .s-category .sl-v-list li {
  width: 130px;
  margin-right: 5px;
}

.selector .sl-v-list li a {
  float: left;
  white-space: nowrap;
  zoom: 1;
  color: #005aa0;
}

.selector .sl-btns {
  display: none;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
}

.selector .sl-ext {
  position: absolute;
  top: 5px;
  right: 10px;
  width: 110px;
  height: auto;
  line-height: 22px;
  overflow: hidden;
  zoom: 1;
}

.selector a.cate:hover {
  color: #f6671a;
}

.selector a.cate.selected {
  color: #f6671a;
}

.container_row_spec .company-logo .el-image {
  width: 180px;
  height: 180px;
  border-radius: 10%;
  border: 1px solid #ededed;
}
.container_row_spec .company-logo >>> .image-slot {
  font-size: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #ededed;
}

.container_row_spec .item-img {
  width: 170px;
  height: 170px;
  text-align: center;
  margin: 18px 5px;
}
.container_row_spec .item-img .el-image {
  width: 170px;
  height: 170px;
  border-radius: 10%;
  border: 1px solid #ededed;
}
.container_row_spec .item-img >>> .image-slot {
  font-size: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #ededed;
}

.container_row_spec .company-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.container_row_spec .product-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.container_row_spec .product-price {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #ff1100;
  font-weight: bold;
}
</style>
